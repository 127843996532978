import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useError } from '../../contexts/errorContext';

interface Props {
  setIsAuthenticated: React.Dispatch<React.SetStateAction<boolean>>;
}

const Authenticate: React.FC<Props> = ({ setIsAuthenticated }) => {
  const { showError } = useError();
  const navigate = useNavigate();
  const [accessCode, setAccessCode] = useState('');
  const [isFormSubmitError, setIsFormSubmitError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const timestamp = new Date().getTime();
      const response = await fetch('api/authenticate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accessCode,
          timestamp,
        }),
      });
      const data = await response.json();
      if (data.result !== 'success') {
        throw new Error('Invalid code');
      } else {
        localStorage.setItem('hasAuthenticated', 'true');
        setIsAuthenticated(true);
        navigate('/profile');
      }
    } catch {
      showError('Invalid Code');
      setIsFormSubmitError(true);
    } finally {
      setIsSubmitting(false);
    }
  };
  const handleChange = (e: any) => {
    setAccessCode(e.target.value);
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        id="Access Code"
        label="Access Code"
        placeholder="Enter access code to proceed."
        fullWidth
        variant="standard"
        value={accessCode}
        onChange={handleChange}
        error={isFormSubmitError}
      />
      <Button
        variant="contained"
        disabled={isSubmitting || accessCode === ''}
        type="submit"
        sx={{ mt: 3, ml: 1 }}
      >
        Go to measurement
      </Button>
    </form>
  );
};

export default Authenticate;
